import {Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {RecentProjectsService} from "@app/shared/layout";
import {UserService} from "@app/core/services";
import {UserDetails} from "@app/model/commomModels";
import {Location, LocationStrategy, PathLocationStrategy} from "@angular/common";

declare var $: any;

@Component({
  selector: 'sa-header',
  templateUrl: './header.component.html',
  // providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class HeaderComponent implements OnInit {


  detail: UserDetails;
  constructor(private router: Router, public userService: UserService) {
    userService.user$.subscribe(it => {
      this.detail = Object.assign({},it);
    });
  }

  ngOnInit() {
  }


  searchMobileActive = false;

  toggleSearchMobile(){
    this.searchMobileActive = !this.searchMobileActive;

    $('body').toggleClass('search-mobile', this.searchMobileActive);
  }

  onSubmit() {
    this.router.navigate(['/miscellaneous/search']);

  }

  logoClick() {
    if (this.detail && this.detail.homeUrl) {
      location.href = this.detail.homeUrl;
    }
  }
}
