import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiAction, JsonApiService, NotificationService, UserService, ValidationService} from "@app/core/services";
import {I18nService} from "@app/shared/i18n/i18n.service";
import {UserDetails} from "@app/model/commomModels";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {RecentProjectsService} from "@app/shared/layout";

@Component({
    selector: 'auth-modal',
    templateUrl: './auth-modal.component.html',
})
export class AuthModalComponent {
    @Input() detail: UserDetails;
    @Output() close = new EventEmitter();
    @ViewChild('modalTemplate') modalTemplate;
    bsModalRef: BsModalRef;
    formGroup: FormGroup;
    private i18n: I18nService;

    constructor(private valid: ValidationService, public userService: UserService, private noti: NotificationService, private jsonApiService: JsonApiService, private modalService: BsModalService) {
        this.i18n = I18nService.getInstance();
        this.formGroup = new FormGroup({
            admSeq: new FormControl('', [Validators.required]),
            useCd: new FormControl('', [Validators.required]),
            admLginPw: new FormControl('', []),
            admLginPwc: new FormControl('', []),
            admNm: new FormControl('', [
                Validators.required
            ]),
            homeUrl: new FormControl('', []),
            email: new FormControl('', [Validators.email])
        });
    }


    update(data: UserDetails) {
        if (this.formGroup.valid) {
            const formData = this.formGroup.value;
            if (formData.admLginPw && formData.admLginPw !== formData.admLginPwc) {
                this.noti.dangerAlert(this.i18n.getTranslation('Password'), this.i18n.getTranslation('Password') +' '+ this.i18n.getTranslation('ValidError'));
                return;
            } else {
                const action = new ApiAction<void>('/auth/detail');
                action.param = formData;
                action.success = (_) => {
                    // this.userService.reloadUserDetails();
                    this.jsonApiService.httpClientHandleSuccess();
                    this.onClose();
                };
                action.error = (_) => {
                };
                this.jsonApiService.put(action);

            }
        } else {
            this.valid.validCheck(this.formGroup);
        }
    }

    show(detail: UserDetails) {
        this.detail = detail;
        // this.setFormGroup(detail);
        // this.detail = detail;
        this.bsModalRef = this.modalService.show(this.modalTemplate);
    }


    onClose() {
        this.bsModalRef.hide();
        this.close.emit(true)
    }
}
