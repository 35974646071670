
export enum MsgCode {
	/** 성공*/
	R00000 = ("성공"),

	/** 공통 필수 데이터가 존재하지 않습니다.*/
	// E10001 = ("공통 필수 데이터가 존재하지 않습니다."), //
	// /** 필수 데이터가 존재하지 않습니다.*/
	// E10002 = ("필수 데이터가 존재하지 않습니다."),
	// /** 데이터가 존재하지 않습니다.*/
	// E10003 = ("데이터가 존재하지 않습니다."),
	// /** 데이터가 올바르지 않습니다.*/
	// E10004 = ("데이터가 올바르지 않습니다."),
	// /** 등록되지 않은 디바이스 입니다.*/
	// E10101 = ("등록되지 않은 디바이스 입니다."),
	// /** 등록되지 않은 사용자 입니다.*/
	// E10102 = ("등록되지 않은 사용자 입니다."),
	// /** 사용 중지 된 사용자입니다.*/
	// E10103 = ("사용 중지 된 사용자입니다."),
	// /** 인증되지 않은 사용자 입니다.*/
	// E10104 = ("인증되지 않은 사용자 입니다."),
	//
	// /** 공통 헤더가 존재하지 않습니다.*/
	// E20001 = ("공통 헤더가 존재하지 않습니다."),
	// /** 공통 헤더 파싱 실패*/
	// E20002 = ("공통 헤더 파싱 실패."),
	//
	// /** DB 오류*/
	// E30000 = ("DB 오류"),
	// /** DB 처리 중 오류가 발생했습니다. */
	// E30001 = ("DB 처리 중 오류가 발생했습니다."),
	//
	// /** 파일 저장 오류 */
	// E80000 = ("파일 저장 오류"),
	// E80001 = ("파일 저장 오류"),

	/** 알수 없음 오류*/
	E99999 = ("알수 없음 오류")

}
