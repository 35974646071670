import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import { Router } from "@angular/router";
import { NotificationService } from "@app/core/services/notification.service";

import {UserService} from "@app/core/services/user.service";
import {CookieService} from "ngx-cookie-service";
import {I18nService} from "@app/shared/i18n/i18n.service";

@Component({
  selector: "sa-logout",
  template: `
<div id="logout" (click)="showPopup()" class="btn-header transparent pull-right">
        <span> <a title="Sign Out"><i class="fa fa-sign-out"></i></a> </span>
  <form #from action="/security/sign-out" method="post" hidden>
    <input type="text" name="_csrf" value="{{cookieService.get('XSRF-TOKEN')}}">
  </form>
    </div>
  `,
  styles: []
})
export class LogoutComponent implements OnInit {

  public user;
  @ViewChild('from') form: ElementRef;

  constructor(
    private userService: UserService,
    private router: Router,
    private notificationService: NotificationService,
    private cookieService: CookieService
  ) {
  }

  showPopup() {
    const yes = I18nService.getInstance().getTranslation('Yes');
    const no = I18nService.getInstance().getTranslation('No');
    const logout = I18nService.getInstance().getTranslation('Sign Out');
    this.notificationService.smartMessageBox(
      {
        title:
          `<i class='fa fa-sign-out txt-color-orangeDark'></i> ${logout} <span class='txt-color-orangeDark'></span>?`,
        content: I18nService.getInstance().getTranslation('msg.confirm.logout'),
        buttons: `[${no}][${yes}]`
      },
      ButtonPressed => {
        if (ButtonPressed === yes) {
          //this.logout();
          const from: HTMLFormElement = this.form.nativeElement;
          from.submit();
        }
      }
    );
  }

  // logout() {
  //   // this.userService.logout().subscribe((it)=>this.router.navigate(["/"]));
  //   // this.userService.logout().subscribe((it)=>location.href='/');
  //   this.userService.logoutAndForWard();
  // }

  ngOnInit() {}
}
