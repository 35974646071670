import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {RecentProjectsService} from "./recent-projects.service";
import {ApiAction, JsonApiService, UserService} from "@app/core/services";
import {BsModalService} from "ngx-bootstrap/modal";
import {BsModalRef} from "ngx-bootstrap/modal/bs-modal-ref.service";
import {Adm, UserDetails} from "@app/model/commomModels";
import {AuthModalComponent} from "@app/shared/layout/header/recent-projects/auth-modal/auth-modal.component";
import {ParticipantModalComponent} from "@app/features/clinical/participant/modal/participant-modal.component";

@Component({
    selector: 'sa-recent-projects',
    templateUrl: './recent-projects.component.html',
    providers: [RecentProjectsService]
})
export class RecentProjectsComponent implements OnInit {

    projects: Array<any>;
    @ViewChild(AuthModalComponent) modal: AuthModalComponent;
    detail: UserDetails;

    constructor(private projectsService: RecentProjectsService, public userService: UserService) {
        userService.user$.subscribe(it => {
            this.detail = Object.assign({},it);
        });
    }

    ngOnInit() {
        this.userService.isAceept()
    }


    openModal(event, template: TemplateRef<any>) {
        event.preventDefault();
        this.modal.show(Object.assign({},this.detail));
    }

    // onAgree(data: Adm | any) {
    //     const action = new ApiAction('/auth/detail');
    //     action.param = data;
    //     action.success = (data) => {
    //         $("#appInfoTable table").DataTable().ajax.reload();
    //         // this.user.reloadUserDetails();
    //         this.bsModalRef.hide()
    //         this.api.httpClientHandleSuccess();
    //     };
    //     this.api.put(action);
    // }

    onClose(event: any) {
        this.userService.reloadUserDetails();
    }

    // clearProjects(){
    //   this.projectsService.clearProjects();
    //   this.projects = this.projectsService.getProjects()
    //
    // }

}
