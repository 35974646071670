

export const languages: any = [
  {
    "key": "us",
    "param": "en_US",
    "alt": "United States",
    "title": "English (US)"
  },

  {
    "key": "kr",
    "param": "ko_KR",
    "alt": "Korea",
    "title": "한국어"
  }
];
