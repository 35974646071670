import {Injectable} from "@angular/core";

import {BehaviorSubject} from "rxjs";
import {JsonApiService} from "@app/core/services/json-api.service";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {ApiAction} from "@app/core/services/index";
import {Router} from "@angular/router";
import {CorpGrp, Metas, UseCd, UserDetails} from "@app/model/commomModels";
import { CookieService } from 'ngx-cookie-service';

export class MetasImp implements Metas {
    corpGrps: CorpGrp[];

    public getCorpGrp(corpGrpSeq: number) {
        let corpGrps: CorpGrp[] = [];
        if (this.corpGrps) {
            corpGrps = this.corpGrps.filter(it => it.corpGrpSeq === Number(corpGrpSeq));
        }
        return corpGrps[0];
    }
}

@Injectable()
export class MetaService {
    public metasSubject = new BehaviorSubject<MetasImp>(undefined);
    constructor(private jsonApiService: JsonApiService, private httpClient: HttpClient, private cookieService: CookieService, private router: Router) {
        this.reload();
    }

    get data() {
        return this.metasSubject.getValue();
    }
    public reload() {
        this.jsonApiService.get<Metas>({
            url: '/auth/metas',
            success: (it: Metas) => {
                this.metasSubject.next(Object.assign(new MetasImp(), it));
            },
            error: (at: HttpErrorResponse) => {
            },
            complete: () => {
            }
        } as ApiAction<Metas>);
    }
}
